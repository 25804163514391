.demo-logo-vertical {
	width: 40px;
	height: 40px;
	margin: 10px;
	background: rgba(255, 255, 255, .2);
	border-radius: 50%;
}

.awater-logo {
	width: 40px;
	height: 40px;
	margin: 10px;
	border-radius: 50%;
	display: flex;
}

.login-logo {
	text-align: center;
	display: block;
		width: 100%;
		height: 120px;
}

body {
	margin: 0px;
}

.report-form-totalToPay {
	border-color: #10a41d !important;
}

.report-form-totalToPay input {
	color: #10a41d !important;
}

.bg-blue {
	background-color: #2779bb;
}

.blue-gradient-background {
	background-image: linear-gradient(to bottom, #2779bb, #1d2a59) !important;
}

.green-gradient-background {
	background-image: linear-gradient(to right, #129e93, #63c18e);
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.txt-grey {
	color: #231f20;
}

.txt-white {
	color: white !important;
}

.ant-table-thead>tr>th>div {
	text-align: center;
}

.ant-modal-footer .ant-btn-primary {
	background-color: #129e93;
}

.ant-table-thead>tr>th {
	background-color: #2779bb !important;
	color: white !important;
}

.ant-input-search-with-button button{
	background-color: #2779bb !important;
}

.ant-menu-item-selected {
	background-color: #ffffff2b !important;
}

.sider-shadow {
	box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.5);
}
.empty-data {
	font-size: large;
	width: 100%;
	height: 433px;
	border-radius: 5px;
	border: 1px solid #2779bb;
}
.user-dropdown:hover {
	cursor: pointer;
}
.customer-db:hover {
	cursor: pointer;
}